import {api} from '../http';
import {apiUrls} from '../../config/Config';

class EstimateSessionDataServiceClass {

  /**
   * Creates a new Estimate Session from it's URL
   **/
  createNewEstimateSession(estimateSessionUrl) {
    return api.post(apiUrls.createNewEstimateSession, {url: estimateSessionUrl});
  }


  /**
   * Creates a new Participant, in a given role, to an Estimate Session identified by  it's URL, given a name
   **/
  //done with heartbeat
  createNewParticipantEstimateSession(estimateSessionUrl, participantName, participantRole) {
    return api.post(`${apiUrls.createNewParticipantToEstimateSession}/${estimateSessionUrl}`, {
      name: participantName,
      role: participantRole
    });
  }

  /**
   * Deletes a Participant, identified by it's name, from the Estimate Session
   **/
  //done with heartbeat
  deleteParticipantFromEstimateSession(estimateSessionUrl, participantId) {
    return api.delete(`${apiUrls.deleteParticipantFromEstimateSession}/${estimateSessionUrl}/${participantId}`,)
  }


  /**
   * Creates a new Item to an Estimate Session identified by  it's URL, given a name
   **/
  //done with heartbeat
  createNewItemInEstimateSession(estimateSessionUrl, itemName) {
    return api.post(`${apiUrls.createNewItemToEstimateSession}/${estimateSessionUrl}`, {name: itemName})
  }

  /**
   * Creates a Round for the given Estimate Session, identified by  it's URL, and an Item, identified by it's ID
   **/
  initializeRound(estimateSessionUrl, itemIdToEstimate) {
    return api.post(`${apiUrls.initializeRound}/${estimateSessionUrl}/${itemIdToEstimate}`,)
  }

  giveEstimate(roundId, participantId, givenEstimate) {
    return api.post(`${apiUrls.giveEstimate}/${roundId}/${participantId}/${givenEstimate}`,)
  }

  removeEstimate(roundId, participantId) {
      return api.post(`${apiUrls.removeEstimate}/${roundId}/${participantId}`,)
  }

  acceptConsensusEstimate(roundId) {
    return api.post(`${apiUrls.acceptConsensusEstimate}/${roundId}`,)
  }

  //done with heartbeat
  deleteItemFromEstimateSession(estimateSessionUrl, itemIdToDelete) {
    return api.delete(`${apiUrls.deleteFromSession}/${estimateSessionUrl}/${itemIdToDelete}`,)
  }

  deleteParticipantFromRound(estimateSessionUrl, roundId, participantIdToDelete) {
    return api.delete(`${apiUrls.deleteParticipant}/${estimateSessionUrl}/${roundId}/${participantIdToDelete}`,)
  }
}

const EstimateSessionDataService = new EstimateSessionDataServiceClass();

export default EstimateSessionDataService;