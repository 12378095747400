import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Typography, Button, Grid } from "@mui/material";

import { useBeforeunload } from "react-beforeunload";

import CreateParticipantModal from "../../Components/PlanningPoker/CreateParticipantModal";
import ParticipantList from "../../Components/PlanningPoker/ParticipantList";
import CreateItemModal from "../../Components/PlanningPoker/CreateItemModal";
import StoriesList from "../../Components/PlanningPoker/StoriesList";
import EstimationRound from "../../Components/PlanningPoker/EstimationRound";
import ThemedHeaderMenu from "../../Components/ThemedHeaderMenu";
import HomePageFooter from "../../Components/HomePageFooter";

import EstimateSessionDataService from "../../service/PlanningPoker/EstimateSessionDataService";
import { api } from "../../service/http";
import { apiUrls } from "../../config/Config";

import { useTheme } from "@mui/material/styles";
import PopupVirtualScrumMaster from "../../Components/PlanningPoker/PopupVirtualScrumMaster";
import ChatBotComponent from "../../Components/ChatBotComponent";

export default function EstimateSession({
  handleChangeParticipantName,
  handleChangeParticipantId,
  participantId,
  participantRole,
  useVirtualScrumMaster,
  scaleValue,
}) {
  let { url } = useParams();
  let navigate = useNavigate();
  const theme = useTheme();
  const estimateSessionUrl = url;

  const [stories, setStories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [activeRoundObj, setActiveRoundObj] = useState([]);

  // Heartbeat mechanism

  // the timestamp of the last update of the Estimate Session, which would require re-rendering
  const [latestUpdateTimestamp, setLatestUpdateTimestamp] = useState();

  const reCalculate = useCallback(() => {
    // Loads from BE all Items in the Estimate Session
    const fetchStories = async () => {
      const result = await api(
        `${apiUrls.retrieveAllItemsFromSession}/${estimateSessionUrl}`
      );
      setStories(result.data);
    };
    fetchStories();

    // // Loads from BE all Participants in the Estimate Session
    const fetchParticipants = async () => {
      const result = await api(
        `${apiUrls.retrieveAllParticipantsFromSession}/${estimateSessionUrl}`
      );
      setParticipants(result.data);

      //Check if the current Participant exists in the Participant List. If not, redirect to home page
      if (
        participantId &&
        participantId !== undefined &&
        !participantExists(result.data, participantId)
      ) {
        navigate("/planningPoker");
      }
    };
    fetchParticipants();

    //Loads from BE the Estimate Round
    const fetchActiveRound = async () => {
      const result = await api.get(
        `${apiUrls.retrieveActiveRoundFromSession}/${estimateSessionUrl}`
      );
      setActiveRoundObj(result.data);
    };
    fetchActiveRound();
  }, [estimateSessionUrl, navigate, participantId]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api(`${apiUrls.heartbeatUrl}/${estimateSessionUrl}`);
      if (!latestUpdateTimestamp || result.data > latestUpdateTimestamp) {
        reCalculate();
        setLatestUpdateTimestamp(result.data);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, [estimateSessionUrl, latestUpdateTimestamp, reCalculate]);

  // END Heartbeat mechanism

  //check if a Participant with a give ID exists in the list of Participants to the Estimate Session
  const participantExists = (data, id) => {
    data.filter((el) => el.id === id);
    return data.length > 0;
  };

  const [appState, setAppState] = React.useState({
    sessionExists: false,
  });

  useEffect(() => {
    // Checks if an estimate session, identified by it's URL, exists
    setAppState({ sessionExists: false });

    api
      .get(`${apiUrls.retrieveEstimateSession}/${estimateSessionUrl}`)
      .then((res) => {
        setAppState({ sessionExists: true });
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setAppState({ sessionExists: false });
        }
      });
  }, [estimateSessionUrl]);

  const createNewParticipantEstimateSession = (participantName) => {
    // create the Participant to the Estimate Session with the role of TEAM MEMBER
    EstimateSessionDataService.createNewParticipantEstimateSession(
      estimateSessionUrl,
      participantName,
      "TEAM_MEMBER"
    ).then((result) => {
      handleChangeParticipantId(result.data);
      reCalculate();
    });
  };

  //deletes the participate to the Estimate Session
  const deleteParticipantFromEstimateSession = async (
    participantIdToDelete
  ) => {
    await EstimateSessionDataService.deleteParticipantFromEstimateSession(
      estimateSessionUrl,
      participantIdToDelete
    );
    if (participantId === participantIdToDelete) {
      navigate("/planningPoker");
    }
    reCalculate();
  };

  useBeforeunload(() => {
    deleteParticipantFromEstimateSession(participantId);
  });

  //Set the Item to be estimated and initializes the Round
  const initializeRound = (itemId) => {
    EstimateSessionDataService.initializeRound(estimateSessionUrl, itemId).then(
      (response) => {
        reCalculate();
      }
    );
  };

  const passOpenModal = () => {
    if (participantRole === "SCRUM_MASTER") {
      return false;
    }
    return true;
  };

  const createNewStory = (itemName) => {
    EstimateSessionDataService.createNewItemInEstimateSession(
      estimateSessionUrl,
      itemName
    ).then(
      // aici daca venea pe raspuns item-ul creat `const newItem = {id: 7, name: 'Story 4', estimate: null}`
      // puteam sa il adaugam la lista de stories -> `setStories({...stories, newItem})`
      // si astfel evitam inca un call de backend ca sa ne aduca lista de stories
      (response) => {
        reCalculate();
      }
    );
  };

  const deleteStory = (id) => {
    EstimateSessionDataService.deleteItemFromEstimateSession(
      estimateSessionUrl,
      id
    ).then((response) => {
      reCalculate();
    });
  };

  const deleteParticipantFromRound = (id) => {
    EstimateSessionDataService.deleteParticipantFromRound(
      estimateSessionUrl,
      activeRoundObj.id,
      id
    ).then((response) => {
      reCalculate();
    });
  };

  const giveEstimate = (value) => {
    EstimateSessionDataService.giveEstimate(
      activeRoundObj.id,
      participantId,
      value
    ).then((response) => {
      reCalculate();
    });
  };

  const acceptEstimate = () => {
    EstimateSessionDataService.acceptConsensusEstimate(activeRoundObj.id).then(
      (response) => {
        reCalculate();
      }
    );
  };

  const removeEstimate = () => {
    EstimateSessionDataService.removeEstimate(
      activeRoundObj.id,
      participantId
    ).then((response) => {
      reCalculate();
    });
  };

  return (
    <Grid
      container
      height={1}
      sx={{ bgcolor: theme.palette.secondary.main }}
      overflow="auto"
    >
      <ThemedHeaderMenu themeSwitch title="Estimate Session" />

      {/* <Typography 
        variant="p" 
        component="div" 
        sx={{
          color: theme.palette.text.primary
        }}>
        HEARTBEAT:
        {estimateSessionUrl} - {latestUpdateTimestamp}
      </Typography> */}

      {!appState.sessionExists && (
        <Grid
          item
          xs={12}
          justifyContent="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography component="p" sx={{ my: 10 }}>
            No estimate session with this ID exists
          </Typography>

          <Button
            variant="contained"
            color="success"
            component={Link}
            to={"/planningPoker"}
          >
            Start a new Estimate Session
          </Button>
        </Grid>
      )}

      {appState.sessionExists && (
        <Grid item container>
          <Grid item md={8} px={4} xs={12} mt={{ xs: 1, md: 3 }}>
            <EstimationRound
              currentRound={activeRoundObj}
              currentParticipantId={participantId}
              deleteParticipant={(id) => deleteParticipantFromRound(id)}
              giveEstimate={giveEstimate}
              scaleValue={scaleValue}
              acceptEstimate={acceptEstimate}
              removeEstimate={removeEstimate}
            />
          </Grid>
          <Grid item xs={12} md={4} mt={-3}>
            <CreateItemModal
              onSubmit={createNewStory}
              estimateSessionUrl={estimateSessionUrl}
            />
            <StoriesList
              initializeRound={initializeRound}
              deleteStory={deleteStory}
              currentParticipantId={participantId}
              stories={stories}
              participants={participants}
            />
            <CreateParticipantModal
              estimateSessionUrl={estimateSessionUrl}
              openModal={passOpenModal()}
              handleChangeParticipantName={(name) => {
                createNewParticipantEstimateSession(name);
                handleChangeParticipantName(name);
              }}
            />
            <ParticipantList
              currentParticipantId={participantId}
              participants={participants}
              deleteParticipant={(id) =>
                deleteParticipantFromEstimateSession(id)
              }
              currentParticipantRole={participantRole}
            />
            {/*<PopupVirtualScrumMaster*/}
            {/*  activeRoundObj={activeRoundObj}*/}
            {/*  scaleValue={scaleValue}*/}
            {/*  items={stories}*/}
            {/*/>*/}
            <ChatBotComponent
                activeRoundObj={activeRoundObj}
                scaleValue={scaleValue}
                items={stories}
            />
          </Grid>
        </Grid>
      )}
      <HomePageFooter />
    </Grid>
  );
}
