class SystemConfigClass {
  BASE_URL_PROTOCOL = process.env.REACT_APP_BASE_URL_PROTOCOL || 'https';
  BASE_URL_HOST = process.env.REACT_APP_BASE_URL_HOST || 'be.estimilo.com';
  BASE_URL_PORT = process.env.REACT_APP_BASE_URL_PORT || 443;
  AXIOS_TIMEOUT = 60000;

  getBaseUrl() {
    return `${this.BASE_URL_PROTOCOL}://${this.BASE_URL_HOST}:${this.BASE_URL_PORT}`;
  }
}

const SystemConfig = new SystemConfigClass();

export default SystemConfig;
