import React, { useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { Grid, Typography, Button, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../App";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

import LinkedInIcon from '@mui/icons-material/LinkedIn';

const ThemedHeaderMenu = ({ themeSwitch, title, handleScrollToLearning, handleScrollToTools, handleScrollToContactUs }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Grid
      container
      item
      xs={12}
      mx={{ xs: 1, md: 5 }}
      mt={4}
      bgcolor={theme.palette.background.light}
      height={80}
      sx={{ borderRadius: "20px" }}
      px={{ xs: 1, md: 3 }}
    >
      <Grid container>
        <Grid item xs={1} alignContent="center" justifyContent="center">
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="Estimilo Logo" style={{ height: "50px" }} />
            <Typography
              variant="p"
              component="p"
              fontWeight={800}
              fontSize={20}
              color="primary"
            >
              Estimilo
            </Typography>
            <Box
                sx={{
                  ml: 1,
                  px: 1,
                  py: 0.5,
                  bgcolor: "darkgreen",
                  color: "white",
                  borderRadius: 1,
                  fontSize: 12,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
            >
              Beta
            </Box>
          </Link>
        </Grid>
        <Grid item xs={1} md={3} />
        <Grid
          container
          item
          xs={10}
          md={8}
          alignContent="center"
          justifyContent="flex-end"
        >
          {themeSwitch && (
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="primary"
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThemedHeaderMenu;
