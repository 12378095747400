import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container } from '@mui/material';

import EstimateSessionDataService from "../../service/PlanningPoker/EstimateSessionDataService";

import DialogWithInput from "../DialogWithInput/DialogWithInput";

export default function CreateParticipantAndNewEstimateSessionForm(props) {

  const [isModalOpen, setModalOpen] = useState(true);

  let navigate = useNavigate();


  const handleValidation = (input) => {
    if (!input.trim()) {
      return "Please enter your name"; // Basic non-empty validation
    }
    return "";
  };

  const handleSubmit = (participantName) => {

      //randomly generate an URL
      const generatedUrl = generateNumber(1000000, 10000000);

      initEstimateSessionAndParticipant(generatedUrl, participantName);

  }

  async function initEstimateSessionAndParticipant(generatedUrl, participantName) {

    try {

      // create the Estimate Session
      await createNewEstimateSession(generatedUrl).then((result) => {
        props.handleChangeParticipantRole('SCRUM_MASTER');
      });

      // create the Participant as the SCRUM MASTER
      await createNewParticipantEstimateSession(generatedUrl, participantName, 'SCRUM_MASTER');
      props.handleChangeParticipantName(participantName);

      navigate("/planningPoker/" + generatedUrl);
    }
    catch (err) {
      console.log('Init Failed', err);
    }

  }

    /**
     * Calls the API for creating a new estimate session
     **/
    const createNewEstimateSession = (url) => {
      let result;
      try {
        result = EstimateSessionDataService.createNewEstimateSession(url);
      } catch (err) {
        console.log('New estimate Session Failed', err)
      }
      return result;
    }


    /**
     * Calls the API for creating a new Participant to the Estimate Session
     **/

    const createNewParticipantEstimateSession = (estimateSessionUrl, participantName, participantRole) => {
      EstimateSessionDataService.createNewParticipantEstimateSession(estimateSessionUrl, participantName, participantRole).then((result) => {
        props.handleChangeParticipantId(result.data);
      });
    }

    /**
     * Generates a random number between min and max
     **/
    const generateNumber = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    return (
      <Container maxWidth="sm">
        <DialogWithInput 
          onSubmit={handleSubmit}
          title='Start an estimate session'
          inputLabel="What's your name?"
          inputPlaceholder='Your name'
          buttonText='Start Session'
          buttonVariant='contained'
          open={isModalOpen} 
          handleClose={() => {navigate('/');setModalOpen(!isModalOpen);}}
          validateInput={handleValidation}/>
      </Container>
    );

}