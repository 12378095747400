import React, {useEffect, useRef } from 'react';

import {api} from "../../service/http";
import {apiUrls} from "../../config/Config";

import {createClientMessage} from "react-chatbot-kit";

import { createCustomMessage } from 'react-chatbot-kit';

const ActionProvider = ({ createChatBotMessage, setState, children, scrumMasterMessage }) => {

    useEffect(()=> {
        if (scrumMasterMessage && scrumMasterMessage.length > 0) {
            // Iterate over each message in the array and call sendCustomChatBotMessage
            scrumMasterMessage.forEach((message) => {
                sendCustomChatBotMessage(message);
            });
        }

    }, [scrumMasterMessage]);

    async function retrieveMessageFromBackend() {
        try {
            // Temporary test by invoking the heartbeat mechanism
            const result = await api(`${apiUrls.heartbeatUrl}/000`);
            return result.data; // Return the data directly
        } catch (err) {
            return null; // Return null in case of an error
        }
    }

    const handleTest = async () => {

        // Retrieve the message from the backend
        const message = await retrieveMessageFromBackend();

        if (message) {
            // Update the state with the retrieved message
            const botMessage = createChatBotMessage('Here is your response from the Backend (timestamp): '+ message);
            const clientMessage = createClientMessage('This is a client message' + message);
            // const customMessage =  createCustomMessage('This is the message content', 'custom', null);

            // Update the state with all the messages
            setState((prev) => {
                return {
                    ...prev,
                    messages: [...prev.messages,  botMessage, clientMessage],
                };
            });

        } else {
            // Handle the case where the message could not be retrieved
        }
    };


    const handleDefault = () => {

        const botMessage = createChatBotMessage('I cannot answer questions yet. An improved version of myself is coming soon :)');

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleHelp = () => {

        const botMessage1 = createChatBotMessage("My name is Ben, I'm your Virtual Scrum Master. My role is to give you advices and guide you in the estimate process.");
        const botMessage2 = createChatBotMessage("Be aware I'm not very smart right now, so you cannot have a conversation with me. An improved version of myself, based on AI, is coming soon.");

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage1, botMessage2],
        }));
    };

    const sendCustomChatBotMessage = (scrumMasterMessage) => {

        // const customChatBotMessage = createCustomMessage(scrumMasterMessage, 'custom', null);

        //choosing to use standard chat bot messages instead of custom messages, as all previous custom messages are updated to the latest one
        const customChatBotMessage = createChatBotMessage(scrumMasterMessage);

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, customChatBotMessage],
        }));
    };


    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleTest,handleDefault, sendCustomChatBotMessage, handleHelp
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;