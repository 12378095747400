import React from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Config from "./config/Config";
import CreateParticipantAndNewEstimateSessionForm
    from "./Components/PlanningPoker/CreateParticipantAndNewEstimateSessionForm";
import EstimateSession from "./Pages/PlanningPoker/EstimateSession";
import HomePage from "./Pages/HomePage";
import LoginForm from "./Components/LoginForm";
import Course from "./Pages/Course/Course";
import ComingSoonHomePage from "./Pages/ComingSoonHomePage";

import {ThemeProvider, createTheme} from "@mui/material/styles";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    },
});

export default function App() {
    // App configuration
    const [participantRole, setParticipantRole] = React.useState();
    const [participantId, setParticipantId] = React.useState();
    const [participantName, setParticipantName] = React.useState();

    function handleChangeParticipantRole(newValue) {
        setParticipantRole(newValue);
    }

    function handleChangeParticipantName(newValue) {
        setParticipantName(newValue);
    }

    function handleChangeParticipantId(newValue) {
        setParticipantId(newValue);
    }

    const [mode, setMode] = React.useState("dark");

    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
            switchToDarkMode: () => {
                setMode(() => "dark");
            },
        }),
        []
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: `'Kanit', sans-serif`,
                },
                palette: {
                    mode,
                    white: "#FFFFFF",
                    black600: "#0F0819",
                    black500: "#333130",
                    black400: "#3C3944",
                    black300: "#6F6C75",
                    black200: "#8A888F",
                    ...(mode === "light"
                        ? {
                            // palette values for light mode

                            primary: {main: "#242424"},
                            secondary: {main: "#F9F9F9"},
                            background: {
                                light: "#FFFFFF",
                            },
                            success: {
                                main: "#BB86FC",
                                contrastText: "#FFFFFF",
                            },
                            info: {
                                main: "#FFFFFF",
                                contrastText: "#4F46E5",
                            },
                            text: {
                                primary: "#242424",
                                secondary: "#666666",
                                border: "#2424242B",
                            },
                        }
                        : {
                            // palette values for dark mode
                            primary: {main: "#FFFFFF"},
                            secondary: {main: "#2d2d2d"},
                            background: {
                                light: "#161616",
                            },
                            success: {
                                main: "#BB86FC",
                                contrastText: "#FFFFFF",
                            },
                            info: {
                                main: "#161616",
                                contrastText: "#4F46E5",
                            },
                            text: {
                                primary: "#FFFFFF",
                                secondary: "#F4F4F4",
                            },
                        }),
                },
            }),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        {/* <Route path="/" caseSensitive={false} element={<ComingSoonHomePage />} /> */}

                        <Route path="/" caseSensitive={false} element={<HomePage/>}/>
                        <Route
                            path="/planningPoker"
                            caseSensitive={false}
                            element={
                                <CreateParticipantAndNewEstimateSessionForm
                                    handleChangeParticipantRole={handleChangeParticipantRole}
                                    handleChangeParticipantName={handleChangeParticipantName}
                                    handleChangeParticipantId={handleChangeParticipantId}
                                />
                            }
                        />
                        <Route
                            path="/planningPoker/:url"
                            caseSensitive={false}
                            element={
                                <EstimateSession
                                    participantRole={participantRole}
                                    participantName={participantName}
                                    participantId={participantId}
                                    handleChangeParticipantName={handleChangeParticipantName}
                                    handleChangeParticipantId={handleChangeParticipantId}
                                    useVirtualScrumMaster={Config.USE_VIRTUAL_SCRUM_MASTER}
                                    scaleValue={Config.ESTIMATE_SCALE}
                                />
                            }
                        />
                        {/*
            <Route
              path="/login"
              caseSensitive={false}
              element={<LoginForm />}
            />
            */}
                        <Route
                            path="/course/EstimatesInAgileSoftwareDevelopment"
                            caseSensitive={false}
                            element={<Course/>}
                        />
                        <Route
                            path="/privacy"
                            caseSensitive={false}
                            element={<PrivacyPolicy/>}
                        />
                        <Route
                            path="/terms"
                            caseSensitive={false}
                            element={<TermsOfService/>}
                        />
                    </Routes>
                </Router>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
