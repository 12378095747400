import React, { useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import {Grid, Typography, Button, IconButton, Box} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "../App";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

const HomePageHeaderMenu = ({ themeSwitch, title, handleScrollToLearning, handleScrollToTools, handleScrollToContactUs }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Grid
      container
      item
      xs={12}
      mx={{ xs: 1, md: 5 }}
      mt={4}
      bgcolor={theme.palette.background.light}
      height={80}
      sx={{ borderRadius: "20px" }}
      px={{ xs: 1, md: 3 }}
    >
      <Grid container>
        <Grid item xs={1} alignContent="center" justifyContent="center">
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img src={logo} alt="Estimilo Logo" style={{ height: "50px" }} />
            <Typography
              variant="p"
              component="p"
              fontWeight={800}
              fontSize={20}
              color="primary"
            >
              Estimilo
            </Typography>
              <Box
                  sx={{
                      ml: 1,
                      px: 1,
                      py: 0.5,
                      bgcolor: "darkgreen",
                      color: "white",
                      borderRadius: 1,
                      fontSize: 12,
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                  }}
              >
                  Beta
              </Box>
          </Link>
        </Grid>
        <Grid item xs={1} md={3} />
        <Grid
          container
          item
          xs={10}
          md={8}
          alignContent="center"
          justifyContent="flex-end"
        >
          <Button
              onClick={handleScrollToTools}
            sx={{
              textTransform: "none",
              fontWeight: 800,
              fontSize: "20px",
              display: { xs: "none", md: "block" },
            }}
            mx={6}
          >
            Tools
          </Button>
            <Button
                onClick={handleScrollToLearning}
                sx={{
                    textTransform: "none",
                    fontWeight: 800,
                    fontSize: "20px",
                    display: { xs: "none", md: "block" },
                }}
                mx={6}
            >
                Training
            </Button>
          {themeSwitch && (
            <IconButton
              sx={{ ml: 1 }}
              onClick={colorMode.toggleColorMode}
              color="primary"
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          )}
          <Button
              onClick={handleScrollToContactUs}
            sx={{
              bgcolor: "#BB86FC",
              borderRadius: "10px",
              marginLeft: "20px",
              color: "white",
              fontWeight: 500,
              fontFamily: `'Roboto', sans-serif`,
              paddingLeft: { xs: "5px", md: "35px" },
              paddingRight: { xs: "5px", md: "35px" },
              display: "flex",
              alignItems: "center",
            }}
          >
            Contact Us
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePageHeaderMenu;
