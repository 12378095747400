import axios from 'axios';
import SystemConfig from "../config/SystemConfig";

let qs = require('qs');

const baseURL = SystemConfig.getBaseUrl() + '/api';

export const api = axios.create({
  baseURL,
  timeout: SystemConfig.AXIOS_TIMEOUT,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'brackets'}),
});