import {useContext, useRef} from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ColorModeContext } from "../App";
import HomePageHeaderMenu from "../Components/HomePageHeaderMenu";
import HomePageFooter from "../Components/HomePageFooter";
import HomePageTitle from "../Components/HomePageTitle";
import FullWidthImage from "../Components/FullWidthImage";
import FullWidthVideo from "../Components/FullWidthVideo";
import TitleWithPreTitle from "../Components/TitleWithPreTitle";
import Card from "../Components/Card";
import FullWidthCard from "../Components/FullWidthCard";

import weFocusBackgroudImage from "../images/weFocusBG.svg";
import weFocusEmptyCardImage from "../images/weFocusEmptyCard.svg";
import weFocus3rdCard from "../images/weFocus3rdCard.svg";
import weOfferCoursesImage from "../images/weOfferCoursesBg.svg";
import futureCoursesImage from "../images/futureCoursesBg.svg";
import complexityBucketImage from "../images/complexityBucketsEstimate.svg";
import planningPokerImage from "../images/planningPokerBg.svg";
import youGoToAgileImage from "../images/youGoToAgileBg.svg";
import videoScrumMasterImage from "../images/videoScrumMasterBg.svg";
import whiteElephantSizingImage from "../images/whiteElephantSizingBg.svg";
import oujaBoardEstimateImage from "../images/oujaBoardEstimateBg.svg";
import futureReleasesImage from "../images/futureReleaseBg.svg";
import aiEstimateEngineImage from "../images/aiEstimateEngineBg.svg";
import aiToolsGamesImage from "../images/aiToolsGamesBg.svg";
import aiBackground from "../images/aiBackground.svg";

import accurateEstimationsIcon from "../icons/accurateEstimationsIcon.svg";
import efficientProjectPlanningIcon from "../icons/efficientProjectPlanningIcon.svg";
import streamlinedWorkflowsIcon from "../icons/streamlinedWorkflowsIcon.svg";
import trainingResourcesIcon from "../icons/trainingResourcesIcon.svg";
import efficientConsensusIcon from "../icons/efficientConsensusIcon.svg";
import enhancedTeamCollabIcon from "../icons/enhancedTeamCollabIcon.svg";
import streamlinedDevelopmentIcon from "../icons/streamlinedDevelopmentProcessIcon.svg";
import efficiencyBoostIcon from "../icons/efficiencyBoostIcon.svg";
import improvedAccuracyIcon from "../icons/improvedAccuracyIcon.svg";
import consistencyAndUniformityIcon from "../icons/consistencyAndUniformity.svg";
import enhancedDecisionIcon from "../icons/enhancedDecisionIcon.svg";
import engagingEstimationIcon from "../icons/engagingEstimationIcon.svg";
import creativityAndCollabIcon from "../icons/creativityAndCollabIcon.svg";
import ReviewCarousel from "../Components/ReviewCarousel";
import ContactForm from "../Components/ContactForm";

const HomePage = () => {
  let navigate = useNavigate();
  const colorMode = useContext(ColorModeContext);
  colorMode.switchToDarkMode();

    const videoRef = useRef(null);
    const handleScrollToLearning = () => {
            if (videoRef.current) {
                videoRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        };

    const toolsRef = useRef(null);
    const handleScrollToTools = () => {
        if (toolsRef.current) {
            toolsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const contactUsRef = useRef(null);
    const handleScrollToContactUs = () => {
        if (contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

  return (
      <Grid container bgcolor="#0F0819" height="100%" overflow="auto">
          <HomePageHeaderMenu handleScrollToLearning={handleScrollToLearning} handleScrollToTools={handleScrollToTools} handleScrollToContactUs={handleScrollToContactUs}/>
          <HomePageTitle/>
          <FullWidthImage/>

          {/*
          <ReviewCarousel/>
          */}

          <Grid container pt={4} rowSpacing={2} height={1}>
              <Grid item xs={12} md={6} height={{xs: 1 / 2, md: 1}}>
                  <Card
                      pb={10}
                      backgroundImage={weFocusBackgroudImage}
                      title="Streamlining Your Software  Project Estimations"
                      description={`Through our unique suite of tools and services, we provide modern, integrated solutions to address the challenges of traditional estimation methods in the software development industry.`}
                      descriptionList={[
                          {
                              icon: accurateEstimationsIcon,
                              text: "Accurate Estimations",
                              id: 1,
                          },
                          {
                              icon: efficientProjectPlanningIcon,
                              text: "Fast go/no-go decisions",
                              id: 2,
                          },
                          {
                              icon: streamlinedWorkflowsIcon,
                              text: "Streamlined Workflows",
                              id: 3,
                          },
                          {
                              icon: trainingResourcesIcon,
                              text: "Training Resources and Courses",
                              id: 4,
                          },
                      ]}
                  />
              </Grid>
              <Grid xs={12} md={6} item container rowSpacing={1} height={1}>
                  <Grid
                      item
                      height={1 / 2}
                      xs={12}
                      display={{xs: "none", md: "block"}}
                  >
                      <Card ml={0} backgroundImage={weFocusEmptyCardImage}/>
                  </Grid>
                  <Grid
                      item
                      height={{xs: 1 / 3, md: 1 / 2}}
                      xs={12}
                      mb={{xs: 0, md: -2}}
                  >
                      <Card
                          ml={0}
                          backgroundImage={weFocus3rdCard}
                          subtitle={`
              → Utilise advanced tools and methodologies to improve the precision of projects estimations \n 
              → Incorporate historical data, industry benchmarks, and expert input to enhance the reliability of estimates \n 
              → Leverage the power of AI to identify patterns and correlations that are not immediately obvious to humans`}
                      />
                  </Grid>
              </Grid>
          </Grid>




              <TitleWithPreTitle ref={toolsRef}
                  preTitle="Tools & Features"
                  title={`Tools to Streamline Project Estimation & Boost Efficiency`}
              />

              <Grid container pt={4} rowSpacing={2} height={1} mx={{xs: 0, md: 2}}>
                  <Grid item xs={12} md={6} height={{xs: 1 / 2, md: 1}}>
                      <Card
                          bigTitle="Planning Poker"
                          backgroundImage={planningPokerImage}
                      />
                  </Grid>
                  <Grid item xs={12} md={6} container rowSpacing={1} height={1}>
                      <Grid item height={1 / 2} xs={12}>
                          <Card
                              ml={0}
                              pt={0}
                              backgroundImage={youGoToAgileImage}
                              title="Your go-to Agile estimating technique!"
                              subtitle={`Gather your team, playfully vote on task efforts, and reach consensus swiftly. Inspired by the fun dynamics of card games, it's a surefire way to streamline estimate efforts!`}
                              descriptionList={[
                                  {
                                      icon: efficientConsensusIcon,
                                      text: "Efficient Consensus Building",
                                      id: 5,
                                  },
                                  {
                                      icon: enhancedTeamCollabIcon,
                                      text: "Enhanced Team Collaboration",
                                      id: 6,
                                  },
                                  {
                                      icon: streamlinedDevelopmentIcon,
                                      text: "Streamlined Estimate Process",
                                      id: 7,
                                  },
                              ]}
                              buttonTitle="Start Estimating Now"
                              buttonClick={() => {
                                  navigate("/planningPoker");
                              }}
                          />
                      </Grid>
                      <Grid
                          item
                          height={{xs: 1 / 4, md: 1 / 2}}
                          xs={12}
                          mb={{xs: 0, md: -4}}
                      >
                          <Card
                              ml={0}
                              backgroundImage={videoScrumMasterImage}
                              title="Virtual Scrum Master"
                              customDescription="<b>Meet Ben, your digital ally!</b> This virtual facilitator enhances your Agile estimation sessions by leveraging cutting-edge technology to provide real-time guidance and support, making it easier than ever to estimate your work effectively."
                          />
                      </Grid>
                  </Grid>
              </Grid>

              <Grid container pt={4} mx={{xs: 0, md: 2}}>
                  <FullWidthCard
                      comingSoon
                      backgroundImage={whiteElephantSizingImage}
                      height="600px"
                      theme="dark"
                      bigTitle="White Elephant Sizing"
                      subtitle="Faster estimation technique focusing on visual and comparative sizing of tasks rather than numerical estimation."
                      descriptionList={[
                          {
                              icon: efficiencyBoostIcon,
                              text: "<b>Efficiency Boost:</b> Quick and intuitive process, especially useful for teams familiar with each other's work. ",
                              id: 8,
                          },
                          {
                              icon: improvedAccuracyIcon,
                              text: "<b>Improved Engagement:</b> Encourages a common high-level understanding of task complexity and effort.",
                              id: 9,
                          },
                      ]}
                      alignText="left"
                  />
              </Grid>

              <Grid container pt={4} mx={{xs: 0, md: 2}}>
                  <FullWidthCard
                      comingSoon
                      theme="dark"
                      backgroundImage={complexityBucketImage}
                      height={{xs: "700px", md: "600px"}}
                      bigTitle="Complexity Buckets Estimate"
                      subtitle="Quick estimation technique for obtaining high-level estimates, allowing upper management to take fast go/no-go decisions."
                      descriptionList={[
                          {
                              icon: consistencyAndUniformityIcon,
                              text: "<b>Consistency and Uniformity:</b> Ideal for teams looking for a quick, high-level understanding of task complexity.",
                              id: 9,
                          },
                          {
                              icon: enhancedDecisionIcon,
                              text: "<b>Enhanced Decision-making:</b> Reduces estimation time by avoiding detailed numerical discussions, thus allowing for fast decision process.",
                              id: 10,
                          },
                      ]}
                      alignText="right"
                  />
              </Grid>

              <Grid container pt={4} mx={{xs: 0, md: 2}}>
                  <FullWidthCard
                      comingSoon
                      theme="dark"
                      backgroundImage={oujaBoardEstimateImage}
                      height="600px"
                      bigTitle="Ouija Board Estimate"
                      subtitle="A fun alternative to planning poker, inspired by the mysterious talking board. "
                      descriptionList={[
                          {
                              icon: engagingEstimationIcon,
                              text: "<b>Engaging Estimation:</b> Quija Board injects excitement into the planning process and increases team engagement.",
                              id: 11,
                          },
                          {
                              icon: creativityAndCollabIcon,
                              text: "<b>Creativity and Collaboration:</b> Encourages creative thinking and fosters collaboration among team members, leading to more dynamic and insightful estimations.",
                              id: 12,
                          },
                      ]}
                      alignText="left"
                  />
              </Grid>


          <FullWidthVideo  ref={videoRef}/>

          <Grid container minHeight="550px" mb={8} mx={{xs: 0, md: 2}}>
              <Grid item xs={12} md={8} mt={6.3}>
                  <Card
                      backgroundImage={weOfferCoursesImage}
                      title="In this course, we'll equip you with essential tools, processes, and best practices to achieve accurate project estimates within Agile software development."
                      description={`→ Estimating in Story Points\n→ Understanding Different Types of Estimates\n→ Estimation Techniques for Scrum Framework`}
                      buttonTitle="View full course"
                      buttonClick={() => {
                          navigate("/course/EstimatesInAgileSoftwareDevelopment");
                      }}
                  />
              </Grid>
              <Grid item xs={12} md={4} pt={6}>
                  <Card
                      ml={0}
                      backgroundImage={futureCoursesImage}
                      comingSoon
                      theme="light"
                      bigTitle="Future courses"
                      description={`Explore a variety of other courses designed to enhance your overall project management and development skills.
                          \n
            Whether you are a Project Manager, a Software Developer or a C-level Manager looking to deepen your understanding of time estimates, these courses are tailored for you.\n
            → Courses available in various formats, including video and HTML \n 
            → Access live or on-demand courses taught by experienced professionals
            `}
                  />
              </Grid>
          </Grid>


          <TitleWithPreTitle
                             preTitle="Future releases"
                             title={`New tools and features we're building`}
          />

              <Grid
                  container
                  pt={4}
                  rowSpacing={1}
                  height={{xs: 1, md: 1}}
                  mx={{xs: 0, md: 2}}
              >
                  <Grid
                      xs={12}
                      md={6}
                      item
                      container
                      rowSpacing={1}
                      height={{xs: 6 / 10, md: 1}}
                  >

                      <Grid item height={{xs: 6 / 10, md: 1}} xs={12} mb={{xs: 0}}>

                          <Card
                              backgroundImage={aiToolsGamesImage}
                              comingSoon
                              disableSubtitleOnMD
                              theme="dark"
                              title="AI Tools, Games & Integrations"
                              subtitle={`We are dedicated to improving project estimation and management with new tools and features that emphasize innovation and collaboration. By using advanced technology and focusing on user experience, we aim to enhance productivity and efficiency. Stay tuned for upcoming developments that will support your software development teams.`}
                              description={`
              → An AI Virtual Scrum Master to assist the estimate sessions \n 
              → Games designed to improve team estimation skills \n
              → Integration with Jira and other Project Management Software
              `}
                          />
                      </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} height={{xs: 5 / 10, md: 1}}>
                      <Card
                          backgroundImage={aiBackground}
                          comingSoon
                          ml={0}
                          theme="light"
                          bigTitle="AI Estimation Engine"
                          description={`
                                  We’re developing a cutting-edge AI Estimation Engine designed to improve the accuracy of your estimates by leveraging your historical data, industry benchmarks, and expert insights.\n\n
                                  Your data privacy is our top priority! We are creating a Private AI Engine using Meta’s Llama 3, the most advanced openly available LLM to date. This engine will operate in a secure cloud environment and be trained exclusively with the data you provide, ensuring complete tenant data isolation. \n 
                                  Although its development is complex, we are dedicated to bringing this innovative feature to life. We recognize that this tool is still a work in progress and plan to offer it as a separate feature in the future. We appreciate your patience and understanding as we work towards this exciting advancement.`
                          }
                      />
                  </Grid>
              </Grid>

              <Grid item xs={12} height="50px"/>

              <TitleWithPreTitle ref={contactUsRef}
                  preTitle="Contact us"
                  title={`Write us a message`}
              />

              <ContactForm/>


              <Grid item xs={12} height="50px"/>

              <HomePageFooter/>

              {/*
      <br/>
      <Link href="/planningPoker" style={{color: theme.palette.text.secondary}}>Start an estimate session using Planning Poker</Link>
      <br/>
      <Link href="/course/EstimatesInAgileSoftwareDevelopment" style={{color: theme.palette.text.secondary}}>View course: Estimates in Agile Software Development</Link>
      <br/>
      <Link href="/login" style={{color: theme.palette.text.secondary}}>Login</Link>
      */}
      </Grid>
);
};

export default HomePage;
