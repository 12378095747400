import {api} from './http';
import {apiUrls} from '../config/Config';

class UserManagementServiceClass {

  /**
   * Logs in a user though the company's SSO
   **/
  doLogin(loginEmail) {
    return api(`${apiUrls.loginWithCompanySSO}/${loginEmail}`,)
 }

}

const UserManagementService = new UserManagementServiceClass();

export default UserManagementService;