import Config from "../../config/Config";

class EstimateServiceClass {

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  /**
   * Obtains the estimate scale to be used
   */
  getScaleToUse(scaleValue) {
    return Config.scaleValuesMap.filter((scale) => scale.scaleName === scaleValue)[0];
  }

  /**
   *  Returns the numeric value from the label estimate, for a given scale
   */
  getNumericValueForScale(scaleValue, givenEstimate) {

    let scaleToUse = Config.scaleValuesMap.filter((scale) => scale.scaleName === scaleValue)[0];
    let numericValue = null;
    scaleToUse.valuesMap.forEach((row) => {
      if (row.label === givenEstimate) {
        numericValue = row.value;
      }
    });
    return numericValue;
  }

  /**
   *  Returns the label value from the numeric estimate, for a given scale
   */
  getLabelForScale(scaleValue, givenEstimate) {

    let scaleToUse = Config.scaleValuesMap.filter((scale) => scale.scaleName === scaleValue)[0];
    let numericValue = null;
    scaleToUse.valuesMap.forEach((row) => {
      if (row.value === givenEstimate) {
        numericValue = row.label;
      }
    });
    return numericValue;
  }


  /**
   * Retrieves true is all Participants have given estimates for the Round, false otherwise
   * **/
  allEstimatesGiven(activeRoundObj) {

    let allEstimatesGiven = true;

    if (activeRoundObj.participantEstimateList !== undefined) {
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if (participant.estimate == null) {
          allEstimatesGiven = false;
        }
      });
    } else {
      allEstimatesGiven = false;
    }
    return allEstimatesGiven;
  }

  /**
   * Retrieves true is at least one Participant have given estimates for the Round, false otherwise
   * **/
  anyEstimatesGiven(activeRoundObj) {

    let anyEstimatesGiven = false;

    if (activeRoundObj.participantEstimateList !== undefined) {
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if (participant.estimate) {
          anyEstimatesGiven = true;
        }
      });
    }
    return anyEstimatesGiven;
  }

  /**
   * Retrieves the second option participant name and estimate, if any
   * **/
  retrieveSeparateOpinionEstimateParticipant(activeRoundObj) {

    //first, build the array of estimate occurrences
    let estimatesOccurrences = []

    let participantArray = activeRoundObj.participantEstimateList;
    let index = 0;
    participantArray.forEach(participant => {
      let existingEstimate = estimatesOccurrences.filter((existingEstimate) => existingEstimate.estimate === participantArray[index].estimate);
      if (existingEstimate === undefined) {
        let newEstimate = {
          "estimate": participant.estimate,
          "participants": [participant.participant.name]
        }
        estimatesOccurrences.push(newEstimate);
      } else {
        existingEstimate.push(participant.participant.name);
      }
      index += 1;
    });


    //now, get the separate opinion participant, if any

    let potentialSeparateOpinionEstimate = estimatesOccurrences.filter((existingEstimate) => 1 === existingEstimate.participants.length);

    if (potentialSeparateOpinionEstimate !== undefined && potentialSeparateOpinionEstimate.length === 1) {
      return {
        "participantName": potentialSeparateOpinionEstimate[0].participants[0],
        "participantEstimate": potentialSeparateOpinionEstimate[0].estimate
      };
    }

  }


  /**
   * Retrieves the lowest estimator Participant in an given round
   *
   *  Note: if more than one Participants have the same highest estimate, the last one in the list is returned.
   * **/
  retrieveLowestEstimate(scaleValue, activeRoundObj) {

    let lowestEstimate = 99999;
    let lowestEstimateParticipantNameList = [];

    if (activeRoundObj.participantEstimateList !== undefined) {
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if ((participant.estimate!=null) && (lowestEstimate >= this.getNumericValueForScale(scaleValue, participant.estimate))) {
          lowestEstimate = this.getNumericValueForScale(scaleValue, participant.estimate);
        }
      });
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if (participant.estimate === this.getLabelForScale(scaleValue, lowestEstimate)) {
          lowestEstimateParticipantNameList.push(participant.participant.name);
        }
      });
    }

    return {
      "lowestEstimate": lowestEstimate,
      "lowestEstimateParticipantNameList": lowestEstimateParticipantNameList
    };

  }

  /**
   * Retrieves the highest estimator Participant in an given round.
   *
   * Note: if more than one Participants have the same highest estimate, the last one in the list is returned.
   * **/
  retrieveHighestEstimate(scaleValue, activeRoundObj) {

    let highestEstimate = -1;
    let highestEstimateParticipantNameList = [];


    if (activeRoundObj.participantEstimateList !== undefined) {
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if ((participant.estimate!=null) && (highestEstimate <= this.getNumericValueForScale(scaleValue, participant.estimate))) {
          highestEstimate = this.getNumericValueForScale(scaleValue, participant.estimate);
        }
      });
      activeRoundObj.participantEstimateList.forEach((participant) => {
        if (participant.estimate === this.getLabelForScale(scaleValue, highestEstimate)) {
          highestEstimateParticipantNameList.push(participant.participant.name);
        }
      });
    }
    return {
      "highestEstimate": highestEstimate,
      "highestEstimateParticipantNameList": highestEstimateParticipantNameList
    };
  }

  /**
   * Check consensus
   * **/
  isConsensus(activeRoundObjAttr) {
    let participantEstimateList = activeRoundObjAttr.participantEstimateList;

    let isConsensusReached = false;

    if (activeRoundObjAttr.participantEstimateList && participantEstimateList.length > 0) {
      let firstEstimate = participantEstimateList[0].estimate;
      isConsensusReached = true;
      participantEstimateList.forEach(function (participantEstimate) {
        if (firstEstimate === null || firstEstimate === "-" || firstEstimate !== participantEstimate.estimate) {
          isConsensusReached = false;
          firstEstimate = participantEstimate.estimate;
        }
      });
    }
    return isConsensusReached;
  }

}

const EstimateService = new EstimateServiceClass();

export default EstimateService;