import React from 'react';
import EstimateService from "./EstimateService";
import {estimateAdvicesList, VSM_BECOMES_VISIBLE, VSM_NO_ESTIMATES_GIVEN_MESSAGE_LIST} from "../../config/Config";
import {VSM_CONSENSUS_MESSAGE_LIST} from "../../config/Config";


class ScrumMasterServiceClass {

    buildToggleSvmToVisible = () => {
        return VSM_BECOMES_VISIBLE;
    }



    export
    const
    buildScrumMasterEngine = (isVisible, activeRoundObj, scaleValue, setScrumMasterMessage, setWelcomeMessage, items) => {
        let compiledScrumMasterMessages = [];


        const buildLowestAndHighestEstimateMessage = (lowestEstimate, highestEstimate) => {
            let messageToBeBuilt = " ";

            //build lowest estimate
            let index = 0;
            while (index < lowestEstimate.lowestEstimateParticipantNameList.length) {
                messageToBeBuilt = messageToBeBuilt + lowestEstimate.lowestEstimateParticipantNameList[index];
                index += 1;
                messageToBeBuilt += lowestEstimate.lowestEstimateParticipantNameList.length !== index ? " OR " : "";
            }

            messageToBeBuilt += " discuss with ";

            //build highest estimate
            index = 0;
            while (index < highestEstimate.highestEstimateParticipantNameList.length) {
                messageToBeBuilt = messageToBeBuilt + highestEstimate.highestEstimateParticipantNameList[index];
                index += 1;
                messageToBeBuilt += highestEstimate.highestEstimateParticipantNameList.length !== index ? " OR " : "";
            }

            messageToBeBuilt += ", please.";

            return messageToBeBuilt;
        };

        const buildSeparateOpinionEstimateMessage = (participantName, participantEstimate) => {
            return " " + participantName + " justify why you estimated to  " + participantEstimate + ", please.";
        }

        // const buildSimilarEstimatedStoriesMessage = (participantGivenEstimate) => {
        //
        //     let messageToBeBuilt = " ";
        //
        //     let similarEstimatedStories = [];
        //     items?.forEach((item) => {
        //             if (item.estimate === participantGivenEstimate) {
        //                 similarEstimatedStories.push(item);
        //             }
        //         }
        //     )
        //
        //     if (similarEstimatedStories!==undefined && similarEstimatedStories.length > 0) {
        //         messageToBeBuilt = " Similar estimated stories are : ";
        //         messageToBeBuilt += similarEstimatedStories.map((similarStory) => {return (similarStory.name) } )
        //     }
        //
        //     return messageToBeBuilt;
        // }

        const buildConsensusReachedMessage = () => {
            return VSM_CONSENSUS_MESSAGE_LIST;
        }

        const buildNoEstimatesGivenMessage = () => {
            return VSM_NO_ESTIMATES_GIVEN_MESSAGE_LIST;
        }

        function getRandomAdviceMessage() {
            return estimateAdvicesList[Math.floor(Math.random()*estimateAdvicesList.length)];
        }

        // IMPORTANT START Logic of buildScrumMasterEngine, based on the state

        //WORKAROUND (the BE throws a string saying "no active round" instead of empty or 404

        if (isVisible) {

            // setWelcomeMessage("");//clean the Welcome Message


            if ((Array.isArray(activeRoundObj) && activeRoundObj.length === 0) || (typeof activeRoundObj === "string")) {
                //no active round
                // give advices that don't belong to the active round
                //TEMP
                compiledScrumMasterMessages.push("No active round yet. Wait for the Scrum Master to start an estimate round for one of the items.");
                compiledScrumMasterMessages.push("Share the URL to invite other estimators to this session.");
            } else {
                //active round

                if (EstimateService.allEstimatesGiven(activeRoundObj)) {

                    if (!EstimateService.isConsensus(activeRoundObj)) {

                        let oneSeparateOpinion = EstimateService.retrieveSeparateOpinionEstimateParticipant(activeRoundObj);

                        if (oneSeparateOpinion !== undefined) {
                            //Advice when one Participant has a different options than the rest of the Participants
                            compiledScrumMasterMessages.push(buildSeparateOpinionEstimateMessage(oneSeparateOpinion.participantName, oneSeparateOpinion.participantEstimate));
                        } else {
                            let lowestEstimate = EstimateService.retrieveLowestEstimate(scaleValue, activeRoundObj);
                            let highestEstimate = EstimateService.retrieveHighestEstimate(scaleValue, activeRoundObj);
                            //Advice when there are two extremes: one participants with the lowest estimate and one with highest estimate.
                            compiledScrumMasterMessages.push(buildLowestAndHighestEstimateMessage(lowestEstimate, highestEstimate));
                        }
                    } else if (EstimateService.allEstimatesGiven(activeRoundObj)) {
                        compiledScrumMasterMessages.push(...buildConsensusReachedMessage());
                    }
                } else {

                    //if no estimates are given yet
                    if (!EstimateService.anyEstimatesGiven(activeRoundObj)) {
                        compiledScrumMasterMessages.push(...buildNoEstimatesGivenMessage());
                    } else { //if some of the estimates are given

                        // TEMP: there are different per Participant, as they are calculated on the client. Should be the same for all Participants.
                        compiledScrumMasterMessages.push(getRandomAdviceMessage());
                    }

                }
            }
        } //do nothing if the VSM is not visible


        // if (activeRoundObj) {
        //
        //     if (EstimateService.allEstimatesGiven(activeRoundObj)) {
        //
        //         if (!EstimateService.isConsensus(activeRoundObj)) {
        //
        //
        //             let oneSeparateOpinion = EstimateService.retrieveSeparateOpinionEstimateParticipant(activeRoundObj);
        //
        //             if (oneSeparateOpinion !== undefined) {
        //                 //Advice when one Participant has a different options than the rest of the Participants
        //                 compiledScrumMasterMessages.push(buildSeparateOpinionEstimateMessage(oneSeparateOpinion.participantName, oneSeparateOpinion.participantEstimate));
        //             } else {
        //
        //
        //                 let lowestEstimate = EstimateService.retrieveLowestEstimate(scaleValue, activeRoundObj);
        //                 let highestEstimate = EstimateService.retrieveHighestEstimate(scaleValue, activeRoundObj);
        //                 //Advice when there are two extremes: one participants with the lowest estimate and one with highest estimate.
        //                 compiledScrumMasterMessages.push(buildLowestAndHighestEstimateMessage(lowestEstimate, highestEstimate));
        //             }
        //         } else if (EstimateService.allEstimatesGiven(activeRoundObj)) {
        //             compiledScrumMasterMessages.push(buildConsensusReachedMessage());
        //         }
        //     } else {
        //         compiledScrumMasterMessages.push(getRandomAdviceMessage());
        //     }
        // } else {
        //     // give advices that don't belong to the active round
        //     //TEMP
        //     compiledScrumMasterMessages.push("No active round yet. Wait for the Scrum Master to start an estimate round for one of the stories");
        // }

        // Commented this messages, as this refers to a specific message to be displayed to the current Participant/User. The current approach is to show same messages to ALL Participants in the session.
        // let similarEstimatedStoriesMessage = buildSimilarEstimatedStoriesMessage(participantGivenEstimate);
        // if (similarEstimatedStoriesMessage.trim()) {
        //     compiledScrumMasterMessages.push(similarEstimatedStoriesMessage);
        // }


        // compile all messages to be showed
        setScrumMasterMessage(compiledScrumMasterMessages.map((message, index) => {
            return (<li key={index}>{message}</li>)
        }));
    };

}

const ScrumMasterService = new ScrumMasterServiceClass();

export default ScrumMasterService;
